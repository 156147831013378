import { AnyFunction, AnyObject } from "core/types";
import React, { useContext, useEffect, useState } from "react";
import { fromRenderProps } from "recompose";
import de from "./locales/de";
import { default as Translations, default as TranslationsType } from "./types";

export type Locale = "de" | "en" | "fr";

type TranslationsContextProps = {
  locale: Locale;
  translations: TranslationsType;
  updateLanguage: Function;
};

function mutateDevTranslations(
  translations: AnyFunction | AnyObject | string,
  path = "",
) {
  if (typeof translations !== "object" || !translations) return;
  for (const [key, value] of Object.entries(translations)) {
    const keyPath = path ? `${path}.${key}` : key;
    if (typeof value === "string") {
      translations[key] = keyPath;
    } else {
      mutateDevTranslations(value, keyPath);
    }
  }
}

async function setDevTranslations(
  setTranslations: (translations: TranslationsType) => void,
) {
  const { default: cloneDeep } = await import("lodash/cloneDeep");
  const devTranslations = cloneDeep(de);
  mutateDevTranslations(devTranslations);
  setTranslations(devTranslations);
}

const updateLanguage = async (
  language: string,
  setTranslations: (translations: TranslationsType) => void,
  setLocale: (locale: Locale) => void,
) => {
  if (language === "dev") {
    setDevTranslations(setTranslations);
    setLocale("en");
  } else if (language === "de") {
    setTranslations(de);
    setLocale("de");
  } else if (language === "fr") {
    import("./locales/fr").then((file) => {
      setTranslations(file.default);
      setLocale("fr");
    });
  } else {
    import("./locales/en").then((file) => {
      setTranslations(file.default);
      setLocale("en");
    });
  }
};

export const TranslationsContext =
  React.createContext<TranslationsContextProps>({
    translations: de,
    locale: "de",
    updateLanguage,
  });

export function withTranslations<Outer = AnyObject>(
  component: React.ComponentType<
    Outer & { locale: Locale; translations: Translations }
  >,
) {
  return fromRenderProps<
    { locale: Locale; translations: Translations },
    Outer,
    TranslationsContextProps
  >(TranslationsContext.Consumer, (value: TranslationsContextProps) => ({
    translations: value.translations,
    locale: value.locale,
  }))(component);
}

export function AsyncTranslationsProvider({
  children,
  defaultLanguage,
  useGetLanguage,
}: {
  children: React.ReactNode;
  defaultLanguage?: string;
  useGetLanguage?: () => string | undefined;
}) {
  const language = useGetLanguage?.() ?? defaultLanguage;
  const [locale, setLocale] = useState<Locale>("fr");
  const [translations, setTranslations] = useState<TranslationsType>(de);

  useEffect(() => {
    if (language) {
      updateLanguage(language, setTranslations, setLocale);
    }
  }, [language]);

  useEffect(() => {
    if (defaultLanguage)
      updateLanguage(defaultLanguage, setTranslations, setLocale);
  }, []);

  return (
    <TranslationsContext.Provider
      value={{
        translations,
        locale,
        updateLanguage: (locale: Locale | "dev") =>
          updateLanguage(locale, setTranslations, setLocale),
      }}
    >
      {children}
    </TranslationsContext.Provider>
  );
}

export function useLocale(): Locale {
  return useContext(TranslationsContext).locale;
}

export function useTranslations(): TranslationsType {
  return useContext(TranslationsContext).translations;
}

export function useUpdateLanguage(): Function {
  return useContext(TranslationsContext).updateLanguage;
}

export function evaluateTranslation(
  translation: string | ((...args: unknown[]) => string),
  ...rest: unknown[]
) {
  return typeof translation === "function" ? translation(...rest) : translation;
}
